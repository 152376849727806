<template>
  <div class="mb-6">
    <label :for="id" class="block mb-2 text-sm font-bold text-gray-900">{{ label }}</label>
    <select :id="id"
      class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
      :required="required">
      <option :value="null" disabled selected>Selecione uma opção</option>
      <option v-for="option in options" :key="option.value" :value="option.value">{{ option.label }}</option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    id: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      required: true
    }
  }
};
</script>

<style scoped>
</style>
