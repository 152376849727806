<template>
  <div v-if="isLoading" class="fixed top-0 left-0 w-full h-full bg-gray-800 opacity-75 flex items-center justify-center z-50">
    <svg class="animate-spin h-12 w-12 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V2.97A9.96 9.96 0 004 12h1zm11.53 5.5a8.01 8.01 0 01-2.76 0h-.54a8.01 8.01 0 01-2.76 0h.46c1.27 0 2.5-.3 3.62-.83l.18-.09c.21-.11.42-.24.62-.38h.43z"></path>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style scoped>

</style>
