<template>
  <div>
    <h1 class="font-bold text-xl uppercase p-3">{{ title }}</h1>
  </div>
  <Bar :data="chartData" :options="options"/>
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default {
  components: {
    Bar
  },
  props: {
    labels: Array,
    datasets: Object,
    title: String
  },
  data() {
    return {
      chartData: {
        labels: this.labels,
        datasets: this.datasets,
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          y: {
            grid: {
              display: false,
            }
          },
          x: {
            grid: {
              display: false, 
            }
          },
        },
        // barThickness: 15,
      },
    }
  }
}
</script>
