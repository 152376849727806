<template>
  <div class="mb-12">
    <router-link to="/">
      <img :src="src" class="h-16 sm:h-20" alt="Wëwa Logo" />
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true
    }
  }
}
</script>

<style></style>
