<template>
  <div class="m-4">
    <TitleComponent
      title="Atualizar dados do veículo"
      subtitle="Preencha devidamente todos os campos para editar os seus dados e, clique no botão abaixo para salvar."
    />
  </div>

  <div class="m-4 text-justify bg-white py-14 px-7 border-y">
    <form @submit.prevent="updateCar">
      <div class="grid gap-6 mb-6 md:grid-cols-2">
        <div>
          <label for="modelo" class="block mb-2 text-sm font-bold text-gray-900"
            >Modelo</label
          >

          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            placeholder="Digite o modelo do veículo"
            type="text"
            id="modelo"
            v-model="model"
          />
        </div>
        <div>
          <label for="marca" class="block mb-2 text-sm font-bold text-gray-900"
            >Marca</label
          >

          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            placeholder="Digite a marca do veículo"
            type="text"
            id="marca"
            v-model="make"
          />
        </div>
        <div>
          <label for="cor" class="block mb-2 text-sm font-bold text-gray-900"
            >Cor</label
          >

          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            placeholder="Digite a cor do veículo"
            type="text"
            id="cor"
            v-model="color"
          />
        </div>
        <div>
          <label for="ano" class="block mb-2 text-sm font-bold text-gray-900"
            >Ano</label
          >

          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            placeholder="Digite o ano do veículo"
            type="number"
            id="ano"
            v-model="year"
          />
        </div>
        <div>
          <label
            for="capacidade"
            class="block mb-2 text-sm font-bold text-gray-900"
            >Capacidade</label
          >

          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            placeholder="Digite a capacidade"
            type="number"
            id="capacidade"
            v-model="capacity"
          />
        </div>
        <div>
          <label
            for="licenca"
            class="block mb-2 text-sm font-bold text-gray-900"
            >Licença</label
          >

          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            placeholder="Digite a licença do veículo"
            type="text"
            id="licenca"
            v-model="license_plate"
          />
        </div>
        <div>
          <label
            for="ultima_r"
            class="block mb-2 text-sm font-bold text-gray-900"
            >Licença</label
          >

          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            placeholder="Digite a data da última revisão"
            type="date"
            id="ultima_r"
            v-model="last_revision"
          />
        </div>
        <div>
          <label
            for="descricao"
            class="block mb-2 text-sm font-bold text-gray-900"
            >Descrição</label
          >

          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            placeholder="Digite uma pequena descrição"
            type="text"
            id="descricao"
            v-model="description"
          />
        </div>
      </div>
      <div class="">
        <FileUploadComponent
          uploadMessage="Clique aqui para anexar imagens do veículo (JPG,PNG)"
          label="Imagens do veículo (Dentro e fora)"
          id=""
        />
      </div>

      <div class="flex justify-end">
        <CustomButton
          label="Atualizar veículo"
          size="medium"
          :style="'orange'"
          class="ml-6"
        >
        </CustomButton>
      </div>
    </form>

    <modal :show="showModal" @close="showModal = false">
      <div class="text-left">
        <h2 class="text-xl font-bold mb-4 uppercase">
          Atualizar Dados do veículo?
        </h2>
        <p class="mb-4">
          Clique no "sim", para confirmar este evento, e atualizar os dados.
        </p>

        <div class="grid grid-flow-col justify-stretch">
          <CustomButton
            :style="'gray'"
            label="Não"
            @click="showModal = false"
            textColor="'text-wewa-orange'"
          />
          <CustomButton
            :style="'gray'"
            label="Sim"
            @click="showModal2 = true"
            class="ml-3"
            textColor="'text-wewa-orange'"
          />
        </div>
      </div>
    </modal>
    <modal :show="showModal2" @close="showModal2 = false">
      <div class="text-left">
        <h2 class="text-xl font-bold mb-4 uppercase">
          Dados Atualizados com sucesso!!
        </h2>
        <p class="mb-4">
          A plataforma usará de agora em diante estes novos dados para o seu
          veículo.
        </p>

        <CustomButton
          :style="'orange'"
          label="Entedido"
          @click="showModal2 = false"
        />
      </div>
    </modal>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent.vue";
import FileUploadComponent from "@/components/form/FileUploadComponent.vue";
import modal from "@/components/modal/Modal";
import CustomButton from "@/components/CustomButton.vue";
import axios from "axios"

export default {
  components: {
    TitleComponent,
    FileUploadComponent,
    CustomButton,
    modal,
  },
  data() {
    return {
      showModal: false,
      showModal2: false,
      model: "",
      make: "",
      color: "",
      year: 0,
      capacity: 0,
      license_plate: "",
      last_revision: "",
      description: ""
    };
  },
  methods: {
    async detailCar() {
      try {
        const carId = this.$route.params.id;
        const token = localStorage.getItem("token");

        if (!token) {
          console.log("Token não encontrado no localStorage");
          return;
        }

        const customHeaderParams = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get(`cars/${carId}`, {
          headers: customHeaderParams,
        });

        console.log(response);

        if (response.status === 200 && response.data.success === true) {
          const carData = response.data.data;
          this.model = carData.model;
          this.make = carData.make;
          this.color = carData.color;
          this.year = carData.year;
          this.capacity = carData.capacity;
          this.license_plate = carData.license_plate;
          this.last_revision = carData.last_revision;
          this.description = carData.description;
        } else {
          console.log("Algo deu errado ao obter os dados do usuário.");
        }
      } catch (error) {
        console.error("Erro ao obter os dados do usuário:", error);
      }
    },

    async updateCar() {
      try {
        const updatedDatas = {};

        if (this.model) {
          updatedDatas.model = this.model;
        }

        if (this.make) {
          updatedDatas.make = this.make;
        }

        if (this.color) {
          updatedDatas.color = this.color;
        }

        if (this.year) {
          updatedDatas.year = this.year;
        }
        if (this.capacity) {
          updatedDatas.capacity = this.capacity;
        }
        if (this.license_plate) {
          updatedDatas.license_plate = this.license_plate;
        }
        if (this.last_revision) {
          updatedDatas.last_revision = this.last_revision;
        }
        if (this.description) {
          updatedDatas.description = this.description;
        }

        if (Object.keys(updatedDatas).length === 0) {
          console.log("Nenhum campo para atualizar.");
          return;
        }

        const token = localStorage.getItem("token");

        if (!token) {
          console.log("Token não encontrado no localStorage");
          return;
        }

        const customHeaderParams = {
          Authorization: `Bearer ${token}`,
        };

        const carId = this.$route.params.id;

        const response = await axios.put(`cars/${carId}`, updatedDatas, {
          headers: customHeaderParams,
        });

        // console.log("Editando", response);

        if (response.status === 200 && response.data.success === true) {
          this.showModal = true;
        }
      } catch (error) {
        console.log("Erro", error);
      }
    },
  },
  mounted() {
    this.detailCar();
  },
};
</script>

<style></style>
