<template>
  <li>
    <router-link :to="to"
                 class="flex items-center p-2 rounded-lg hover:bg-wewa-orange hover:text-white"
                 :class="{ 'bg-wewa-orange text-white': isActive }"
                 :aria-current="isActive ? 'page' : null">
      <span class="ms-3">{{ text }}</span>
      <span v-if="showNotifications" class="inline-flex items-center justify-center w-3 h-3 p-3 ms-3 text-sm font-medium text-white bg-wewa-orange rounded-full">{{ notificationCount }}</span>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    to: String,
    text: String,
    showNotifications: Boolean,
    notificationCount: Number
  },
  computed: {
    isActive() {
      const currentPath = this.$route.path;
      const isExactMatch = currentPath === this.to;
      const isPrefixMatch = currentPath.startsWith(`${this.to}/`);
      const singularPath = this.to.replace(/s$/, '');
      const isSingularMatch = currentPath.startsWith(`${singularPath}/`);

      return isExactMatch || isPrefixMatch || isSingularMatch;
    }
  }
}
</script>

<style>

</style>
