import { createRouter, createWebHistory } from "vue-router";
 
import dashboard from '../pages/master/dashboard'

import home from '../pages/home'

import user from '../pages/user/index'
import user_register from '../pages/user/create'
import user_show from '../pages/user/show'
import user_edit from '../pages/user/edit'

import passenger from '../pages/passenger/index'
import passenger_show from '../pages/passenger/show'
import passenger_edit from '../pages/passenger/edit'
import passenger_feedback from '../pages/passenger/feedbacks'

import driver from '../pages/driver/index'
import driver_register from '../pages/driver/create'
import driver_show from '../pages/driver/show'
import driver_edit from '../pages/driver/edit'
import driver_feedback from '../pages/driver/feedbacks'

import vehicle_register from '../pages/driver/vehicle/create'
import vehicle_edit from '../pages/driver/vehicle/edit'

import outflow from '../pages/outflow/index'
import outflow_register from '../pages/outflow/create'
import outflow_show from '../pages/outflow/show'
import outflow_edit from '../pages/outflow/edit'

import inflow from '../pages/inflow/index'
import inflow_register from '../pages/inflow/create'
import inflow_show from '../pages/inflow/show'
import inflow_edit from '../pages/inflow/edit'

import profile from '../pages/profile'
import login from '../pages/auth/login'
import notFound from '../pages/error/404'

import loginGuard from "../guard/login"
import dashboardGuard from "../guard/dashboard"

  const routes = [
    {
      name: 'Dashboard',
      path: '/',
      component: dashboard,
      beforeEnter: dashboardGuard,
      children: [
         {
          name: 'home',
          path: '/',
          component:home,
          meta:{
            showDefaultNavbar: true
          }
        },

        //user 
         {
          name: 'user',
          path: '/users',
          component:user,
        },
        {
          name: 'user_register',
          path: '/user/register',
          component:user_register,
          meta:{
            showDefaultNavbar: false
          }
        },
        {
          name: 'user_show',
          path: '/user/show/:id',
          component:user_show,
          meta:{
            showDefaultNavbar: false
          }
        },
        {
          name: 'user_edit',
          path: '/user/edit/:id',
          component:user_edit,
          meta:{
            showDefaultNavbar: false
          }
        },

        //passenger 
         {
          name: 'passenger',
          path: '/passengers',
          component:passenger,
        },
        {
          name: 'passenger_show',
          path: '/passenger/show/:id',
          component:passenger_show,
          meta:{
            showDefaultNavbar: false
          }
        },
        {
          name: 'passenger_edit',
          path: '/passenger/edit/:id',
          component:passenger_edit,
          meta:{
            showDefaultNavbar: false
          }
        },
        {
          name: 'passenger_feedback',
          path: '/passenger/feedback/:id',
          component:passenger_feedback,
          meta:{
            showDefaultNavbar: false
          }
        },

        //driver 
         {
          name: 'driver',
          path: '/drivers',
          component:driver,
        },
        {
          name: 'driver_register',
          path: '/driver/register',
          component:driver_register,
          meta:{
            showDefaultNavbar: false
          }
        },
        {
          name: 'driver_show',
          path: '/driver/show/:id',
          component:driver_show,
          meta:{
            showDefaultNavbar: false
          }
        },
        {
          name: 'driver_edit',
          path: '/driver/edit/:id',
          component:driver_edit,
          meta:{
            showDefaultNavbar: false
          }
        },
        {
          name: 'driver_feedback',
          path: '/driver/feedback/:id',
          component:driver_feedback,
          meta:{
            showDefaultNavbar: false
          }
        },
        // vehicle
        {
          name: 'vehicle_register',
          path: '/vehicle/register',
          component:vehicle_register,
          meta:{
            showDefaultNavbar: false
          }
        },
        {
          name: 'vehicle_edit',
          path: '/vehicle/edit/:id',
          component:vehicle_edit,
          meta:{
            showDefaultNavbar: false
          }
        },


        //outflow 
        {
          name: 'outflow',
          path: '/outflows',
          component:outflow,
        },
        {
          name: 'outflow_register',
          path: '/outflow/register',
          component:outflow_register,
          meta:{
            showDefaultNavbar: false
          }
        },
        {
          name: 'outflow_show',
          path: '/outflow/show/:id',
          component:outflow_show,
          meta:{
            showDefaultNavbar: false
          }
        },
        {
          name: 'outflow_edit',
          path: '/outflow/edit/:id',
          component:outflow_edit,
          meta:{
            showDefaultNavbar: false
          }
        },


        //inflow 
        {
          name: 'inflow',
          path: '/inflows',
          component:inflow,
        },
        {
          name: 'inflow_register',
          path: '/inflow/register',
          component:inflow_register,
          meta:{
            showDefaultNavbar: false
          }
        },
        {
          name: 'inflow_show',
          path: '/inflow/show/:id',
          component:inflow_show,
          meta:{
            showDefaultNavbar: false
          }
        },
        {
          name: 'inflow_edit',
          path: '/inflow/edit/:id',
          component:inflow_edit,
          meta:{
            showDefaultNavbar: false
          }
        },





        {
          name: 'profile',
          path: '/profile',
          component:profile
        }
      ],
    },
    {
      name: 'login',
      path: '/login',
      component:login,
      beforeEnter: loginGuard,
    }, 
    {
      name: '/notFound',
      path: '/:404',
      component:notFound
    }, 
  ];
const router = Router();
export default router;
function Router() {
    const router = new createRouter({
        history: createWebHistory(),
        routes,
    });
    return router;
}

  