<template>
  <div>
    <h3 class="text-2xl font-bold text-left py-2">Profile Page</h3>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>