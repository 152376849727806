<template>
  <div class="m-4">
    <TitleComponent title="Atualizar dados da saída"
      subtitle="Preencha devidamente todos os campos para editar os dados e, clique no botão abaixo para salvar." />
  </div>

  <overlayPreLoader :isLoading="isLoadingSubmit" />

  <div class="m-4 text-justify bg-white py-14 px-7 border-y">

    <div v-if="isLoadingDetail" class="flex justify-center items-center py-10">
      <InnerPreLoaderComponent />
    </div>

    <div v-else>

    <form @submit.prevent="updateOutflow">
      <div class="grid gap-6 mb-6 md:grid-cols-2">

        <div>
          <label for="type" class="block mb-2 text-sm font-bold text-gray-900">Tipo</label>
          <select id="type"
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            required="required">
            <option :value="null" disabled selected>-- Selecione o tipo --</option>
            <option v-for="option in typeOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
          </select>

        </div>
        <div>
          <label for="first_name" class="block mb-2 text-sm font-bold text-gray-900">Valor</label>
          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            placeholder="1000" required id="first_name" type="number" v-model="value" />
        </div>
      </div>
      <div class="">

        <label for="description" class="block mb-2 text-sm font-bold text-gray-900">Descrição</label>
        <textarea id="description" rows="3"
          class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Fale em detalhes sobre esta entrada a ser registrada..." required
          v-model="description"></textarea>

      </div>

      <div class="flex justify-end">
        <CustomButton :style="'orange'" label="Atualizar dados" />
      </div>

    </form>
  </div>
    <modal :show="showModal" @close="showModal = false">
      <div class="text-left">
        <h2 class="text-xl font-bold mb-4 uppercase">Atualizar Dados da Saída?</h2>
        <p class="mb-4">Clique no "sim", para confirmar este evento, e atualizar os dados.</p>

        <div class="grid grid-flow-col justify-stretch">
          <CustomButton :style="'gray'" label="Não" @click="showModal = false" textColor="'text-wewa-orange'" />
          <CustomButton :style="'gray'" label="Sim" @click="showModal2 = true" class="ml-3"
            textColor="'text-wewa-orange'" />
        </div>

      </div>
    </modal>
    <modal :show="showModal2" @close="showModal2 = false">
      <div class="text-left">
        <h2 class="text-xl font-bold mb-4 uppercase">Dados atualizados com sucesso!!</h2>
        <p class="mb-4">A plataforma usará de agora em diante estes novos dados para este registro de saída</p>

        <CustomButton :style="'orange'" label="Entedido" @click="showModal2 = false" />
      </div>
    </modal>
    <modal :show="showErrorModal" @close="showErrorModal = false">
      <div class="text-left">
        <h2 class="text-xl font-bold mb-4 uppercase">{{ errorMessage.code }}</h2>
        <p class="mb-4">{{ errorMessage.message }}</p>
        <CustomButton :style="'orange'" label="Entendido" @click="showErrorModal = false" />
      </div>
    </modal>

  </div>
</template>

<script>
import TitleComponent from "../../components/TitleComponent.vue";
import CustomButton from "@/components/CustomButton.vue";
import modal from '@/components/modal/Modal';
import overlayPreLoader from '@/components/preLoader/OverlayPreLoader.vue';
import InnerPreLoaderComponent from "../../components/preLoader/InnerPreLoader.vue"; 
import axios from "axios";

export default {
  components: {
    TitleComponent,
    CustomButton,
    modal,
    overlayPreLoader,
    InnerPreLoaderComponent
  },
  data() {
    return {
      typeOptions: [
        { label: 'Comissão', value: 'comission' },
        { label: 'Empréstimo', value: 'loan' },
        { label: 'Aquisição de ação', value: 'share_acquisition' }
      ],
      showModal: false,
      showModal2: false,
      value: "",
      type: "",
      document: "",
      date: "",
      description: "",
      isLoadingDetail: false,
      isLoadingSubmit: false,
      errorMessage: {
        code: "",
        message: ""
      },
      showErrorModal: false
    };
  },
  methods: {
    async detailInflow() {
      this.isLoadingDetail=true;
      try {
        const outflowId = this.$route.params.id;
        const token = localStorage.getItem("token");

        if (!token) {
          this.errorMessage.code = "Erro de autenticação";
          this.errorMessage.message = "Token não encontrado. Por favor, faça login novamente.";
          this.showErrorModal = true;
          return;
        }

        const customHeaderParams = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get(`outflows/${outflowId}`, {
          headers: customHeaderParams,
        });

        if (response.status === 200 && response.data.success === true) {
          const outflowData = response.data.data;

          this.value = outflowData.value;
          this.type = outflowData.type;
          this.document = outflowData.document;
          this.date = outflowData.date;
          this.description = outflowData.description;
        } else {
          this.errorMessage.code = "Erro ao obter registros de saídas";
              this.errorMessage.message = "Ocorreu um erro. Por favor, tente novamente mais tarde.";
              this.showErrorModal = true
        }
      } catch (error) {
        console.error("Erro ao obter registros de saídas:", error);
        this.errorMessage.code = "Erro ao obter registros de saídas";
        this.errorMessage.message = "Ocorreu um erro ao tentar obter os registros de saídas. Por favor, tente novamente mais tarde.";
        this.showErrorModal = true;
      }finally{
        this.isLoadingDetail=false;
      }
    },
    async updateOutflow() {
      try {
        const updatedFields = {};

        if (this.value) {
          updatedFields.value = this.value;
        }

        if (this.type) {
          updatedFields.type = this.type;
        }

        if (this.document) {
          updatedFields.document = this.document;
        }

        if (this.date) {
          updatedFields.date = this.date;
        }

        if (this.description) {
          updatedFields.description = this.description;
        }

        if (Object.keys(updatedFields).length === 0) {
          this.errorMessage.code = "Nenhum campo para atualizar.";
            this.errorMessage.message = "Nenhum campo recebeu atualização.";
            this.showErrorModal = true;
          return;
        }

        const token = localStorage.getItem("token");

        if (!token) {
          this.errorMessage.code = "Token Não Encontrado";
            this.errorMessage.message = "Token não encontrado no armazenamento local.";
            this.showErrorModal = true;
          return;
        }

        const customHeaderParams = {
          Authorization: `Bearer ${token}`,
        };

        const outflowId = this.$route.params.id;

        const response = await axios.put(`outflows/${outflowId}`, updatedFields, {
          headers: customHeaderParams,
        });

        // console.log("Editando", response);

        if (response.status === 200 && response.data.success === true) {
          this.showModal = true;
        }
      } catch (error) {
        this.isLoadingSubmit = false;

        let validationErrors;

        switch (error.response ? error.response.status : null) {
          case 422:
            validationErrors = error.response.data.error.errors;
            if (validationErrors) {
              const firstErrorKey = Object.keys(validationErrors)[0];
              this.errorMessage.code = "Erro de Validação";
              this.errorMessage.message = validationErrors[firstErrorKey][0];
            } else {
              this.errorMessage.code = "Erro de Validação";
              this.errorMessage.message = "Erro de validação desconhecido.";
            }
            break;
          case 401:
            this.errorMessage.code = "Token Inválido ou Expirado";
            this.errorMessage.message = "Sua sessão expirou. Faça login novamente.";
            break;
          case 403:
            this.errorMessage.code = "Acesso Negado";
            this.errorMessage.message = "Você não tem permissão para realizar esta ação.";
            break;
          case 404:
            this.errorMessage.code = "Recurso não encontrado";
            this.errorMessage.message = "O recurso solicitado não foi encontrado.";
            break;
          default:
            if (error.response) {
              this.errorMessage.code = "Erro do Servidor";
              this.errorMessage.message = "Ocorreu um erro no servidor. Tente novamente mais tarde.";
            } else if (error.request) {
              this.errorMessage.code = "Sem Resposta do Servidor";
              this.errorMessage.message = "Não foi possível obter resposta do servidor. Verifique sua conexão de rede.";
            } else {
              this.errorMessage.code = "Erro na Requisição";
              this.errorMessage.message = "Ocorreu um erro ao enviar a requisição. Tente novamente.";
            }
            break;
        }

        this.showErrorModal = true;
      }finally{
        this.isLoadingSubmit=false
      }
    },
  },
  async mounted() {
    await this.detailInflow();
  },
}
</script>

<style></style>