<template>
  <div class="flex items-start justify-center flex-col h-24 rounded bg-black rounded-2xl ">
      <p class="ml-5 text-5xl text-white font-bold"> {{title}} </p>
      <p class="ml-7 text-sl text-white font-bold  "> {{subtitle}} </p>
    </div>
</template>
<script>  
  export default{
    props:{
      title:String,
      subtitle: String
    }
  }
</script>