<template>
  <div class="ml-14">
    <h3 class="text-[40px] font-bold text-left py-2">Bem-vindo, ADMIN.</h3>
    <p class="text-left text-xl ">Tenha o controle geral da plataforma</p>
  </div>

  <div class="bg-wewa-orange-2 grid grid-cols-3 gap-4 my-4 p-8">
    <DarkCard title="1K" subtitle="Total de usuários"></DarkCard>
    <DarkCard title="50K" subtitle=" Total de passageiros"></DarkCard>
    <DarkCard title="10K" subtitle="Total de motoristas"></DarkCard>
  </div>

  <div class="grid grid-cols-3 gap-4 mb-4">
    <div class="col-span-2 p-8 rounded bg-white h-auto">
      <div v-if="chartData">
        <Bar :labels="chartData.labels" :datasets="chartData.datasets" title="Resumo anual" />
      </div>
      <div v-else>
        Loading...
      </div>
    </div>
    <div class="rounded bg-white h-auto p-2">
      <Doughnut :labels="chartDataProp.labels" :datasets="chartDataProp.datasets" title="Resumo Financeiro" />

    </div>
  </div>
  <div class="m-8">
    <TableComponent :columns="colunas" :rows="dados" :filters="filters" :filterKey="filterKey" :idKey="idKey"
      :urlPrefix="urlPrefix" />
  </div>
</template>

<script>
import TableComponent from '../components/TableComponent.vue'
import DarkCard from '../components/DarkCardComponent.vue'
import Doughnut from '@/components/charts/DoughnutChart.vue'
import Bar from '@/components/charts/BarChart.vue'
export default {
  components: {
    TableComponent,
    DarkCard,
    Doughnut,
    Bar
  },
  data() {
    return {
      colunas: ['ID', 'Nome', 'Nº do bilhete', 'Nº de telefone'],
      dados: [
        { id: 1, Nome: 'Apple MacBook Pro 17"', 'Nº do bilhete': 'Sliver', 'Nº de telefone': 'Laptop', user: true },
        { id: 2, Nome: 'Microsoft Surface Pro', 'Nº do bilhete': 'White', 'Nº de telefone': 'Laptop PC', driver: true },
        { id: 3, Nome: 'Magic Mouse 2', 'Nº do bilhete': 'Black', 'Nº de telefone': 'Accessories', passenger: true }
      ],
      filters: [
        { texto: 'Usuários', valor: 'user' },
        { texto: 'Motoristas', valor: 'driver' },
        { texto: 'Passageiros', valor: 'passenger' },
      ],
      chartData: {
        labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
        datasets: [
          {
            label: 'Sales Data',
            backgroundColor: '#FF620B',
            data: [10, 10, 90, 70, 100, 20, 30, 35, 40]
          }
        ]
      },
      chartDataProp: {
        labels: ['Entrada', 'Saída', ''],
        datasets: [
          {
            backgroundColor: ['#FF610C', '#F0030B', '#0D8FF'],
            data: [45, 45, 10]
          }
        ]
      },
    };//return

  }, //data


}
</script>

<style></style>
