<template>
  <div class="flex justify-between py-8">
    <!-- Conteúdo do título e subtítulo -->
    <div class="text-left">
      <h3 class="text-[40px] font-extrabold ">{{ title }}</h3>
      <p class=" text-xl">{{ subtitle }}</p>
    </div>

    <!-- Aparece dependendo do value de showButton-->
    <div v-if="showButton=='true'" class="flex items-end pr-3">
      
      <router-link v-if="routerLink" :to="routerLink"
        class="text-nowrap inline-flex relative items-center py-[10px] px-[50px] w-full text-sm font-medium rounded-xl border-gray-200 bg-wewa-orange hover:bg-white text-white hover:text-wewa-orange transition duration-400 ease-in-out">
        {{ textButton }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String, // Título da página
    subtitle: String, // Subtítulo da página
    textButton: String, // Texto do botão
    showButton: { // Indica se o botão deve ser mostrado ou não
      type: Boolean,
      default: false
    },
    routerLink: String, // Router link para o botão
  }
};
</script>
