<template>
  <div>
    <h1 class="font-bold text-xl uppercase p-3">{{ title }}</h1>
  </div>
  <Doughnut :data="chartDataProp" :options="options" />
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'vue-chartjs'

ChartJS.register(ArcElement, Tooltip, Legend)

export default {
  name: 'DoughnutChart',
  components: { Doughnut },
  props: {
    labels: Array,
    datasets: Object,
    title: String
  },
  data() {
    return {
      chartDataProp: {
        labels: this.labels,
        datasets: this.datasets
      },
      options: {
        responsive: true,
        //aspectRatio: 1.5,// altura do gráfico
        cutout: '15%', // raio da rosquinha
        plugins: {
          legend: {
            display: false
          }
        },

      }
    }
  }
}
</script>