<template>
  <router-link :to="to" :class="computedClasses">
    {{ buttonText }}
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    style: {
      type: String,
      default: "orange" // Default style: orange or transparent
    },
    size: {
      type: String,
      default: "medium" // Default size: small, medium, large
    }
  },
  computed: {
    computedClasses() {
      let backgroundClass = "";
      let textColorClass = "";
      let hoverTextColorClass = "";

      // Determine background and text color classes based on style prop
      switch (this.style) {
        case "orange":
          backgroundClass = "bg-wewa-orange hover:bg-white";
          textColorClass = "text-white";
          hoverTextColorClass = "hover:text-wewa-orange";
          break;
        case "transparent":
          backgroundClass = "bg-transparent hover:bg-white";
          textColorClass = "text-wewa-orange";
          hoverTextColorClass = "hover:text-wewa-orange";
          break;
        default:
          backgroundClass = "bg-wewa-orange hover:bg-white";
          textColorClass = "text-white";
          hoverTextColorClass = "hover:text-wewa-orange";
      }

      return [
        `focus:ring-4`,
        `focus:outline-none`,
        `focus:ring-blue-300`,
        `font-bold`,
        `rounded-xl`,
        `text-sm`,
        `px-5`,
        `py-2.5`,
        `text-center`,
        `border`,
        `border-gray-300`,
        backgroundClass,
        textColorClass,
        hoverTextColorClass,
        `transition`,
        `duration-400`,
        `ease-in-out`,
        `capitalize`,
        `inline-block`
      ];
    }
  }
}
</script>

<style scoped>
</style>
