<template>
  <aside id="logo-sidebar" :class="{ 'translate-x-full': !sidebarOpen, 'sm:translate-x-0': sidebarOpen }"
    class="fixed top-0 left-0 z-40 w-64 h-screen transition-transform bg-white border-r">
    <div class="h-full px-3 py-4 overflow-y-auto">
      <logo :src="logoSrc"/>
      <template v-for="(group, index) in sidebarGroups" :key="index">
        <h3 class="text-sm text-gray-500 mb-2 text-start font-semibold">{{ group.title }}</h3>
        <ul class="space-y-2 font-medium mb-10">
          <SidebarItem v-for="item in group.items" :key="item.route" :to="item.route" :text="item.text" :showNotifications="item.showNotifications" :notificationCount="item.notificationCount" />
        </ul>
      </template>
    </div>
  </aside>
</template>


<script>
import SidebarItem from '@/components/master/Sidebar/Items.vue';
import logo from '@/components/LogoComponent.vue'

export default {
  props: {
    sidebarOpen: Boolean,
    logoSrc: String,
    sidebarGroups: Array
  },
  components: {
    SidebarItem, 
    logo
  }
}
</script>

<style>
/* Add your styles here */
</style>
