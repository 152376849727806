<template>
  <div class="mb-6">
    <p class=""></p>
    <label :for="id" class="block mb-2 text-sm font-bold text-gray-900">{{ label }}</label>
    <label for="dropzone-file"
      class="flex flex-col items-center justify-center w-full h-32 border-2 border-black border-dashed rounded-xl cursor-pointer bg-gray-100 hover:bg-gray-200">
      <div class="flex flex-col items-center justify-center pt-5 pb-6">
        <svg class="w-8 h-8 mb-4" aria-hidden="true"  data-v-52a72b4a="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" d="M12 3.5v17M20.5 12h-17"></path></svg>
        <p v-if="!selectedFile" class="mb-2 text-sm text-gray-500">{{ uploadMessage }}</p>
        <template v-else>
          <p class="mb-2 text-sm text-gray-500">Arquivo selecionado: {{ selectedFile.name }}</p>
          <p class="text-xs text-gray-500">Tamanho do arquivo: {{ formatFileSize(selectedFile.size) }}</p>
        </template>
      </div>
      <input id="dropzone-file" type="file" class="hidden" @change="onFileSelected" />
    </label>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    uploadMessage: {
      type: String,
      default: "Clique aqui para anexar imagens"
    },
    id: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      selectedFile: null
    };
  },
  methods: {
    onFileSelected(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFile = file;
      }
    },
    formatFileSize(size) {
      if (size === 0) return '0 Bytes';
      const k = 1024;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      const i = Math.floor(Math.log(size) / Math.log(k));
      return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }
  }
};
</script>

<style scoped>
</style>
