<!-- ProfileImage.vue -->
<template>
  <img :src="imageUrl" class="rounded-[32px] w-36 h-36" :alt="altText">
</template>

<script>
export default {
  props: {
    imageUrl: String,
    altText: String
  }
}
</script>

<style>
</style>
