<template>
  <transition name="modal-fade">
    <div v-if="show" class="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50" @click.self="close">
      <div class="modal bg-white rounded-lg shadow-lg p-8" :style="{ width: modalWidth }">
        <slot></slot>
        <button type="button" class="btn-close absolute top-0 right-0 mt-2 mr-2" @click="close" aria-label="Close modal">x</button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    modalWidth: {
      type: String,
      default: '500px' 
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>


<style>
  .modal {
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }
</style>
