<template>
  <nav v-if="showDefaultNavbar" class="ml-4 bg-white border-gray-20 border-b">
    <div class="flex flex-wrap items-center justify-between mx-auto p-2">
      <div class="w-full">
        <div class="flex items-end justify-end space-x-4">
          <div class="flex" @click="toggleDrop">
            <img class="w-10 h-10 rounded-full" :src="avatarSrc" alt="">
            <div class="font-semibold text-left">
              <div>{{ userName }}</div>
              <div class="text-xs text-gray-500 dark:text-gray-400 uppercase">{{ userRole }}</div>
            </div>
          </div>

        </div>
        <div v-show="showDropDown"
          class="absolute right-[10px] z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
          <div class="py-1 text-left" role="none">
            <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1">Perfil</a>
            <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1">Outro link</a>
            <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1"
              @click="logout">Sair</a>
            <!-- <form method="POST" action="#" role="none">
              <button type="submit" class="text-gray-700 block w-full px-4 py-2 text-left text-sm" role="menuitem"
                tabindex="-1">Sign out</button>
            </form> -->
          </div>
        </div>
      </div>
    </div>
  </nav>
  <nav v-else class="px-4 pt-8">
    <!-- Conteúdo do Navbar Alternativo -->
    <div class=" flex items-center justify-between mx-auto p-2">
      <div>
        <button @click="goBack" class=" ml-64 inline-flex bg-white rounded-3xl py-3 px-6 font-bold">
          <svg class="w-6 mr-2" data-v-52a72b4a="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
            fill="currentColor">
            <path fill-rule="evenodd"
              d="M2.461 12a.75.75 0 01.75-.75l17.79.012a.75.75 0 11-.002 1.5L3.21 12.75a.75.75 0 01-.749-.75z"
              clip-rule="evenodd"></path>
            <path fill-rule="evenodd"
              d="M10.517 4.47a.75.75 0 01.001 1.06L4.06 12l6.458 6.47a.75.75 0 01-1.061 1.06l-6.988-7a.75.75 0 010-1.06l6.988-7a.75.75 0 011.06 0z"
              clip-rule="evenodd"></path>
          </svg> <span class="text-[#0F0E0CCC]">Voltar</span>
        </button>
      </div>
      <div>
        <button class="bg-white rounded-2xl p-2"><svg width="32" height="32" viewBox="0 0 32 32" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M26.6673 18.6666C26.3122 18.6747 25.959 18.6117 25.6286 18.4813C25.2982 18.351 24.9971 18.156 24.7431 17.9077C24.489 17.6594 24.2872 17.3629 24.1493 17.0355C24.0115 16.7081 23.9404 16.3565 23.9403 16.0013C23.9402 15.6461 24.0111 15.2945 24.1488 14.967C24.2865 14.6396 24.4882 14.3429 24.7421 14.0945C24.996 13.8461 25.297 13.6509 25.6273 13.5205C25.9577 13.39 26.3108 13.3268 26.666 13.3346C27.3627 13.35 28.0257 13.6376 28.5131 14.1358C29.0005 14.6339 29.2735 15.3031 29.2737 16C29.2738 16.6969 29.0012 17.3662 28.514 17.8646C28.0269 18.363 27.364 18.6509 26.6673 18.6666ZM8.00062 16C8.00865 16.3551 7.94565 16.7082 7.81533 17.0387C7.685 17.3691 7.48997 17.6702 7.24168 17.9242C6.9934 18.1782 6.69687 18.3801 6.36949 18.5179C6.04212 18.6558 5.69051 18.7268 5.3353 18.7269C4.98009 18.727 4.62845 18.6561 4.30101 18.5185C3.97357 18.3808 3.67693 18.1791 3.42852 17.9252C3.18011 17.6713 2.98493 17.3703 2.85444 17.0399C2.72394 16.7095 2.66077 16.3564 2.66862 16.0013C2.68403 15.3046 2.97159 14.6415 3.46974 14.1541C3.96789 13.6668 4.63705 13.3938 5.33397 13.3936C6.03089 13.3934 6.70018 13.6661 7.19858 14.1532C7.69698 14.6403 7.98486 15.3032 8.00062 16ZM18.6673 16C18.6753 16.3551 18.6123 16.7082 18.482 17.0387C18.3517 17.3691 18.1566 17.6702 17.9083 17.9242C17.6601 18.1782 17.3635 18.3801 17.0362 18.5179C16.7088 18.6558 16.3572 18.7268 16.002 18.7269C15.6468 18.727 15.2951 18.6561 14.9677 18.5185C14.6402 18.3808 14.3436 18.1791 14.0952 17.9252C13.8468 17.6713 13.6516 17.3703 13.5211 17.0399C13.3906 16.7095 13.3274 16.3564 13.3353 16.0013C13.3507 15.3046 13.6383 14.6415 14.1364 14.1541C14.6346 13.6668 15.3037 13.3938 16.0006 13.3936C16.6976 13.3934 17.3668 13.6661 17.8652 14.1532C18.3636 14.6403 18.6515 15.3032 18.6673 16Z"
              fill="black" />
          </svg>
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    avatarSrc: String,
    userName: String,
    userRole: String,
    showDefaultNavbar: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      showDropDown: false,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Navegar para trás
    },

    logout() {
      localStorage.removeItem('token')
      this.$router.push('/login');
    },
    toggleDrop() {
      this.showDropDown = !this.showDropDown;
    }
  }
  }
</script>

<style></style>
