<template>
  <div class="m-4">
    <TitleComponent title="Registrar usuário"
      subtitle="Preencha devidamente todos os campos, para registar o novo usuário" />
  </div>

  <overlayPreLoader :isLoading="isLoading" />

  <div class="m-4 text-justify bg-white py-14 px-7 border-y">

    <form @submit.prevent="registerUser">
      <div class="grid gap-6 mb-6 md:grid-cols-2">
        <div>
          <label for="first_name" class="block mb-2 text-sm font-bold text-gray-900">Primeiro nome</label>
          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            placeholder="Digite o primeiro nome" required id="first_name" type="text" v-model="first_name" />
        </div>
        <div>
          <label for="last_name" class="block mb-2 text-sm font-bold text-gray-900">Sobrenome</label>
          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            placeholder="Digite o segundo nome" required id="last_name" type="text" v-model="last_name" />
        </div>
      </div>
      <div class="grid gap-6 mb-6 md:grid-cols-2">
        <!-- <div>
          <label for="last_name" class="block mb-2 text-sm font-bold text-gray-900">Nº do Bilhete</label>
          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            placeholder="Digite o nº do bilhete"
            required
            id=""
            type="text"
          />
        </div> -->
        <div>
          <label for="phone_number" class="block mb-2 text-sm font-bold text-gray-900">Nº de telefone</label>
          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            placeholder="Ex.: +244 987654321" required id="phone_number" type="text" v-model="phone_number" />
        </div>
      </div>
      <div class="mb-6">
        <label for="email" class="block mb-2 text-sm font-bold text-gray-900">Email</label>
        <input
          class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
          placeholder="Ex.: email@exemplo.com" required type="email" v-model="email" />
      </div>
      <div class="grid gap-6 mb-6 md:grid-cols-2">
        <div class="mb-6">
          <label for="role" class="block mb-2 text-sm font-bold text-gray-900">Nível de acesso</label>
          <select id="role"
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            v-model="selectedRole" required>
            <option :value="null" disabled selected>Selecione o nível de acesso</option>
            <option v-for="option in roles" :key="option.value" :value="option.value">{{ option.value }}</option>
          </select>
        </div>
        <!-- <div>
          <SelectFieldComponent
            label="Gênero"
            id="genre"
            :options="genderOptions"
            required
          />
        </div>
      </div>
      <div class="grid gap-6 mb-6 md:grid-cols-2">
        <div>
          <SelectFieldComponent
            label="Cargo"
            id="position"
            :options="positionOptions"
            required
          />
        </div>
        <div>
          <SelectFieldComponent
            label="Nível de acesso"
            id="accessLevel"
            :options="accessLevelOptions"
            required
          />
        </div>
        <div>
          <SelectFieldComponent
            label="Província"
            id="province"
            :options="provinceOptions"
            required
          />
        </div>
        <div>
          <SelectFieldComponent
            label="Município"
            id="municipality"
            :options="municipalityOptions"
            required
          />
        </div> -->
        <div class="">
          <label for="password" class="block mb-2 text-sm font-bold text-gray-900">Senha</label>
          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            placeholder="•••••••••" required id="password" type="password" v-model="password" />
        </div>
        <div class="">
          <label for="password_confirmation" class="block mb-2 text-sm font-bold text-gray-900">Confirmar senha</label>
          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            placeholder="•••••••••" required id="password_confirmation" type="password"
            v-model="password_confirmation" />
        </div>
      </div>

      <div class="">
        <FileUploadComponent uploadMessage="Clique aqui para anexar imagens do bilhete de identidade" />
      </div>
      <div class="flex justify-end">
        <CustomButton label="Registrar usuário" :style="'orange'" size="large" type="submit" />
      </div>
    </form>

    <modal :show="showModal" @close="showModal = false">
      <div class="text-left">
        <h2 class="text-xl font-bold mb-4 uppercase">
          Usuário registrado com sucesso!!
        </h2>
        <p class="mb-4">
          O seu novo usuário foi registrado. Agora, foram enviandas as
          informações para o seu endereço de email para que possa ter acesso
        </p>
        <CustomButton label="Entedido" :style="'orange'" size="medium" @click="showModal = false" />
      </div>
    </modal>
    <modal :show="showErrorModal" @close="showErrorModal = false">
      <div class="text-left">
        <h2 class="text-xl font-bold mb-4 uppercase">{{ errorMessage.code }}</h2>
        <p class="mb-4">{{ errorMessage.message }}</p>
        <CustomButton :style="'orange'" label="Entendido" @click="showErrorModal = false" />
      </div>
    </modal>
  </div>
</template>

<script>
import TitleComponent from "../../components/TitleComponent.vue";
// import InputFieldComponent from "@/components/form/InputFieldComponent.vue";
// import SelectFieldComponent from "@/components/form/SelectFieldComponent.vue";
import FileUploadComponent from "@/components/form/FileUploadComponent.vue";
import modal from "@/components/modal/Modal";
import CustomButton from "@/components/CustomButton.vue";
import overlayPreLoader from '@/components/preLoader/OverlayPreLoader.vue';
import axios from "axios";

export default {
  components: {
    TitleComponent,
    // InputFieldComponent,
    // SelectFieldComponent,
    FileUploadComponent,
    CustomButton,
    modal,
    overlayPreLoader,
  },
  data() {
    return {
      showModal: false,
      email: "",
      phone_number: "",
      password: "",
      password_confirmation: "",
      roles: [],
      selectedRole: "",
      first_name: "",
      last_name: "",
      errorMessage: {
        code: "",
        message: ""
      },
      showErrorModal: false,
      isLoading: false,
    };
  },
  methods: {
    async fetchRoles() {
      
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          this.errorMessage.code = "Token Não Encontrado";
          this.errorMessage.message = "Token não encontrado no armazenamento local.";
          this.showErrorModal = true;
          return;
        }

        const customHeaderParams = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get('permissions/roles', {
          headers: customHeaderParams,
        });
        if (response.status === 200 && response.data.success === true) {
          this.roles = response.data.data.map((role) => ({
            value: role.name
          }));
        } else {
          this.errorMessage.code = "Erro ao Obter Níveis de Acesso";
          this.errorMessage.message = "Ocorreu um erro ao tentar obter os níveis de acesso. Por favor, tente novamente mais tarde.";
          this.showErrorModal = true;
        }
      } catch (error) {
        console.error("Erro ao obter os níveis de acesso:", error);
        this.errorMessage.code = "Erro ao Obter Níveis de Acesso";
        this.errorMessage.message = "Ocorreu um erro ao tentar obter os níveis de acesso. Por favor, tente novamente mais tarde.";
        this.showErrorModal = true;
      }
    },
    async registerUser() {
      this.errorMessage.code = "";
      this.errorMessage.message = "";
      this.showErrorModal = false;
      try {
        if (
          this.email &&
          this.phone_number &&
          this.password &&
          this.password_confirmation &&
          // this.role &&
          this.first_name &&
          this.last_name
        ) {
          this.isLoading = true;

          const registerData = {
            email: this.email,
            phone_number: this.phone_number,
            password: this.password,
            password_confirmation: this.password_confirmation,
            role: this.selectedRole,
            first_name: this.first_name,
            last_name: this.last_name,
          };

          const token = localStorage.getItem("token");

          if (!token) {
            this.errorMessage.code = "Token Não Encontrado";
            this.errorMessage.message = "Token não encontrado no armazenamento local.";
            this.showErrorModal = true;
            return;
          }

          const customHeaderParams = {
            Authorization: `Bearer ${token}`,
          };

          const response = await axios.post(`users`, registerData, {
            headers: customHeaderParams,
          });

          if (response.status === 200 && response.data.success === true) {
            (this.email = ""),
              (this.phone_number = ""),
              (this.password = ""),
              (this.password_confirmation = ""),
              (this.role = ""),
              (this.first_name = ""),
              (this.last_name = "");
            this.showModal = true;
          }
        }
      } catch (error) {
        this.isLoading = false;

        let validationErrors;

        switch (error.response ? error.response.status : null) {
          case 422:
            validationErrors = error.response.data.error.errors;
            if (validationErrors) {
              const firstErrorKey = Object.keys(validationErrors)[0];
              this.errorMessage.code = "Erro de Validação";
              this.errorMessage.message = validationErrors[firstErrorKey][0];
            } else {
              this.errorMessage.code = "Erro de Validação";
              this.errorMessage.message = "Erro de validação desconhecido.";
            }
            break;
          case 401:
            this.errorMessage.code = "Token Inválido ou Expirado";
            this.errorMessage.message = "Sua sessão expirou. Faça login novamente.";
            break;
          case 403:
            this.errorMessage.code = "Acesso Negado";
            this.errorMessage.message = "Você não tem permissão para realizar esta ação.";
            break;
          case 404:
            this.errorMessage.code = "Recurso não encontrado";
            this.errorMessage.message = "O recurso solicitado não foi encontrado.";
            break;
          default:
            if (error.response) {
              this.errorMessage.code = "Erro do Servidor";
              this.errorMessage.message = "Ocorreu um erro no servidor. Tente novamente mais tarde.";
            } else if (error.request) {
              this.errorMessage.code = "Sem Resposta do Servidor";
              this.errorMessage.message = "Não foi possível obter resposta do servidor. Verifique sua conexão de rede.";
            } else {
              this.errorMessage.code = "Erro na Requisição";
              this.errorMessage.message = "Ocorreu um erro ao enviar a requisição. Tente novamente.";
            }
            break;
        }

        this.showErrorModal = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
  async mounted() {
    await this.fetchRoles();
    this.registerUser();
  },
};
</script>

<style></style>