<template>
  <div class="m-4">
    <TitleComponent title="Registrar motorista"
      subtitle="Preencha devidamente todos os campos, para registar o novo motorista." />
  </div>

  <div class="m-4 text-justify bg-white py-14 px-7 border-y">
    <div class="flex justify-end text-wewa-orange text-bold pb-12">{{ currentStep }}º passo de {{ totalSteps }}</div>
    <form @submit.prevent="showModal = true">
      <template v-if="currentStep === 1">
        <!-- Etapa 1 -->
        <div class="mb-6">
          <InputFieldComponent label="Nome do motorista" placeholder="Digite o nome do motorista" id="name" required />
        </div>
        <div class="grid gap-6 mb-6 md:grid-cols-2">
          <div>
            <SelectFieldComponent label="Gênero" id="genre" :options="genderOptions" required />
          </div>

          <div>
            <InputFieldComponent label="Número de telefone" placeholder="Ex.: +244 987654321" id="phone" required
              type="number" />
          </div>
        </div>
        <div class="mb-6">
          <InputFieldComponent label="Email" placeholder="Ex.: email@exemplo.com" required type="email" id="email" />
        </div>
        <div class="grid gap-6 mb-6 md:grid-cols-2">

        </div>
        <div class="grid gap-6 mb-6 md:grid-cols-2">

          <div>
            <SelectFieldComponent label="Província" id="province" :options="provinceOptions" required />
          </div>
          <div>
            <SelectFieldComponent label="Município" id="municipality" :options="municipalityOptions" required />
          </div>
        </div>
        <div class="flex justify-end">
          <CustomButton label="Avançar" size="medium" :style="'orange'" @:click.prevent="nextStep" />
        </div>

      </template>

      <template v-else-if="currentStep === 2">
        <!-- Etapa 2 -->
        <div class="grid gap-6 mb-6 md:grid-cols-2">
          <div>
            <InputFieldComponent label="Marca do seu veículo" placeholder="Digite  marca do veículo" id="name"
              required />
          </div>
          <div>
            <InputFieldComponent label="Modelo do veículo" placeholder="Digite o modelo do veículo" id="name"
              required />
          </div>
          <div>
            <InputFieldComponent label="Matrícula do veículo" placeholder="Digite a matrícula do veículo" id="name"
              required />
          </div>
          <div>
            <InputFieldComponent label="Kilometraagem do veículo" placeholder="Digite a kilometragem do veículo"
              id="name" required />
          </div>
          <div>
            <InputFieldComponent label="Número de lugares disponíveis" placeholder="2" id="phone" required
              type="number" />
          </div>
          <div>
            <SelectFieldComponent label="Tipo de veículo" id="genre" :options="genderOptions" required />
          </div>
        </div>
        <div class="">

          <FileUploadComponent uploadMessage="Clique aqui para anexar imagens do veículo (JPG,PNG)"
            label="Imagens do veículo (Dentro e fora)" id="" />

        </div>
        <div class="flex justify-end">
          <CustomButton label="Voltar" size="medium" :style="'gray'" @:click.prevent="prevStep" />
          <CustomButton label="Avançar" size="medium" :style="'orange'" @:click.prevent="nextStep" class="ml-6" />
        </div>
      </template>

      <template v-else-if="currentStep === 3">
        <!-- Etapa 3 -->
        <div class="grid gap-6 mb-6 md:grid-cols-2">

          <div>
            <InputFieldComponent label="Nº de Bilhete" placeholder="Digite o nº do bilhete" id="bi" required />
          </div>
          <div>
            <InputFieldComponent label="Nº da carta de condução" placeholder="00000000000000000" id="phone" required
              type="number" />
          </div>
        </div>
        <div class="">

          <FileUploadComponent uploadMessage="Clique aqui para anexar imagens do bilhete de identidade."
            label="Bilhete de identidade" id="" />

        </div>
        <div class="">

          <FileUploadComponent uploadMessage="Clique aqui para anexar imagens da carta de condução."
            label="Carta de condução" id="" />

        </div>

        <div class="flex justify-end">
          <CustomButton label="Voltar" size="medium" :style="'gray'" @:click.prevent="prevStep" />
          <CustomButton label="Registrar usuário" size="medium" :style="'orange'" class="ml-6" />
        </div>
      </template>
    </form>

    <modal :show="showModal" @close="showModal = false">
      <div class="text-left">
        <h2 class="text-xl font-extrabold mb-4 uppercase">Motorista registrado com sucesso!!</h2>
        <p class="mb-4">O seu novo motorista foi registrado. Agora, foram enviadas as informações para o seu endereço de email para que possa ter acesso</p>
        <CustomButton label="Entedido" :style="'orange'" size="medium" @click="showModal = false" />
      </div>
    </modal>

  </div>
</template>

<script>
import TitleComponent from "../../components/TitleComponent.vue";
import InputFieldComponent from "@/components/form/InputFieldComponent.vue";
import SelectFieldComponent from "@/components/form/SelectFieldComponent.vue"
import FileUploadComponent from '@/components/form/FileUploadComponent.vue';
import modal from '@/components/modal/Modal';
import CustomButton from '@/components/CustomButton.vue';

export default {
  components: {
    TitleComponent,
    InputFieldComponent,
    SelectFieldComponent,
    FileUploadComponent,
    CustomButton,
    modal,
  },
  data() {
    return {
      currentStep: 1,
      totalSteps: 3,
      genderOptions: [
        { label: 'Masculino', value: 'male' },
        { label: 'Feminino', value: 'female' }
      ],
      municipalityOptions: [
        { label: 'Desenvolvedor', value: 'developer' },
        { label: 'Designer', value: 'designer' },
        { label: 'Gerente de Projeto', value: 'project_manager' }
      ],
      provinceOptions: [
        { label: 'Desenvolvedor', value: 'developer' },
        { label: 'Designer', value: 'designer' },
        { label: 'Gerente de Projeto', value: 'project_manager' }
      ],
      showModal: false
    };
  },
  methods: {
    nextStep() {
      this.currentStep++;
    },
    prevStep() {
      this.currentStep--;
    },
  }
}
</script>

<style></style>