<template>
  <div class="my-5">
    <p class="text-wewa-orange font-bold mb-2">
      {{ marca }} 
    </p>
    <div :key="chave">
      <p>{{ modelo }}</p>
      <p>{{ matricula }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    marca: String,
    modelo: String,
    matricula: String
  }
}
</script>
