<template>
  <div class="m-4">
    <TitleComponent
      title="Motoristas "
      subtitle="Tenha o acesso e controle de todos os dados dos seus motoristas."
      textButton="+ Novo"
      showButton="true"
      :routerLink="{ name: 'driver_register' }"
    />

    <div v-if="isLoading" class="flex justify-center items-center py-10">
      <PreLoaderComponent />
    </div>

    <div v-else>
    <TableComponent
      :columns="colunas"
      :rows="drivers"
      :filterKey="filterKey"
      :idKey="idKey"
      :urlPrefix="urlPrefix"
      :showDelete="false"
    />
    <div
      class="pagination flex flex-row items-center justify-center gap-4 py-5"
    >
      <button
        class="bg-white text-wewa-orange px-3 py-2 rounded-lg"
        @click="prevPage"
        :disabled="currentPage === 1"
      >
        Anterior
      </button>
      <span>{{ currentPage }} de {{ totalPages }}</span>
      <button
        class="bg-white text-wewa-orange px-3 py-2 rounded-lg"
        @click="nextPage"
        :disabled="currentPage === totalPages"
      >
        Próxima
      </button>
    </div>
  </div>

  <modal :show="showErrorModal" @close="showErrorModal = false">
      <div class="text-left">
        <h2 class="text-xl font-bold mb-4 uppercase">{{errorMessage.code}}</h2>
        <p class="mb-4">{{ errorMessage.message }}</p>
        <CustomButton :style="'orange'" label="Entendido" @click="showErrorModal = false" />
      </div>
    </modal>

  </div>
</template>

<script>
import TableComponent from "../../components/TableComponent.vue";
import TitleComponent from "../../components/TitleComponent.vue";
import PreLoaderComponent from "../../components/preLoader/InnerPreLoader.vue"; 
import CustomButton from "@/components/CustomButton.vue";
import modal from "@/components/modal/Modal.vue";
import axios from "axios";
export default {
  components: {
    TableComponent,
    TitleComponent,
    PreLoaderComponent,
    modal, 
    CustomButton
  },
  data() {
    return {
      colunas: ["ID", "Nome", "Email", "Nº de telefone"],
      filters: [],
      filterKey: "id",
      idKey: "id",
      urlPrefix: "driver",
      drivers: [],
      currentPage: 1,
      driversPerPage: 5,
      totalPages: 0,
      isLoading: false,
      errorMessage: {
        code: "",
        message: ""
      },
      showErrorModal: false
    };
  },
  methods: {
    async listDrivers() {
      this.isLoading = true;
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          this.errorMessage.code = "Erro de autenticação";
          this.errorMessage.message = "Token não encontrado. Por favor, faça login novamente.";
          this.showErrorModal = true;

          await this.$router.push("/");
          return;
        }

        const customHeaderParams = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get(`drivers`, {
          headers: customHeaderParams,
        });

        if (response.status === 200 && response.data.success === true) {
          // console.log("okkk", response.data.data);

          this.drivers = response.data.data.map((driver) => {
            const firstName = driver.user ? driver.user.first_name : "";
            const lastName = driver.user ? driver.user.last_name : "";
            const email = driver.user ? driver.user.email : "";
            const phoneNumber = driver.user ? driver.user.phone_number : "";

            return {
              id: driver.id,
              full_name:
                (firstName + " " + lastName).trim().substring(0, 15) +
                (firstName.length + lastName.length > 15 ? "..." : ""),
              email: email.substring(0, 18) + (email.length > 18 ? "..." : ""),
              phone_number: phoneNumber,
            };
          });

          // Calcular o número total de páginas
          this.totalPages = Math.ceil(
            this.drivers.length / this.driversPerPage
          );

          // Extrair os usuários para a página atual
          const startIndex = (this.currentPage - 1) * this.driversPerPage;
          const endIndex = startIndex + this.driversPerPage;
          this.drivers = this.drivers.slice(startIndex, endIndex);
        } else {
          this.errorMessage.code = "Erro ao obter motoristas";
              this.errorMessage.message = "Ocorreu um erro ao obter mototoristas. Tente novamente mais tarde.";
              this.showErrorModal = true
        }
      } catch (error) {
        console.error("Erro:", error);
        switch (error.response ? error.response.status : null) {
          case 401:
          this.errorMessage.code = "Token Inválido ou Expirado";
          this.errorMessage.message = "Sua sessão expirou. Faça login novamente.";
            break;
          case 403:
            this.errorMessage.code = "Acesso negado";
            this.errorMessage.message = "Você não tem permissão para realizar esta ação.";
            break;
          case 404:
            this.errorMessage.code = "Recurso não encontrado";
            this.errorMessage.message = "O recurso solicitado não foi encontrado.";
            break;
          default:
          if (error.response) {
              this.errorMessage.code = "Erro do Servidor";
              this.errorMessage.message = "Ocorreu um erro no servidor. Tente novamente mais tarde.";
            } else if (error.request) {
              this.errorMessage.code = "Sem Resposta do Servidor";
              this.errorMessage.message = "Não foi possível obter resposta do servidor. Verifique sua conexão de rede.";
            } else {
              this.errorMessage.code = "Erro na Requisição";
              this.errorMessage.message = "Ocorreu um erro ao enviar a requisição. Tente novamente.";
            }
            break;
        }
          this.showErrorModal = true;
      }finally{
        this.isLoading = false;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.listDrivers();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.listDrivers();
      }
    },
  },
  mounted() {
    this.listDrivers();
  },
};
</script>

<style></style>
