<template>
  <button :class="computedClasses" @click="handleClick">
    {{ label }}
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: "medium" // small, medium, large
    },
    textColor: {
      type: String
    },
    style: {
      type: String,
      default: "default" // Opções: default, orange(Que é a cor laranja do wewa), transparent, red, white, gray
    },
  },
  methods: {
    handleClick() {
      this.$emit('click');
    }
  },
  computed: {
    computedClasses() {
      let colorClass = "";
      switch (this.style) {
        case "orange":
          colorClass = "bg-wewa-orange hover:bg-white text-white hover:text-wewa-orange";
          break;
        case "gray":
          colorClass = "bg-gray-100 hover:bg-white text-gray-950 border border-gray-200";
          break;
        case "white":
          colorClass = "bg-white hover:bg-white text-wewa-orange border border-gray-100";
          break;
        case "transparent":
          colorClass = "bg-transparent hover:bg-white text-wewa-orange border border-gray-300";
          break;
        case "red":
          colorClass = "bg-red-500 hover:bg-red-600 text-white hover:text-white";
          break;
        default:
          colorClass = "bg-blue-500 hover:bg-blue-600 text-white hover:text-white";
      }
      return [
        colorClass,
        `text-${this.textColor}`,
        `py-${this.size === 'small' ? 1 : (this.size === 'medium' ? 2 : 3)}`,
        `px-${this.size === 'small' ? 2 : (this.size === 'medium' ? 4 : 6)}`,
        'rounded-lg',
        'focus:outline-none',
        'transition',
        'duration-400',
        'ease-in-out',
        'font-semibold',
        'px-10'
      ];
    }
  }
};
</script>

<style scoped>
button {
  cursor: pointer;
}
</style>
