<template>
  <div class="m-4">
    <div v-if="isLoading" class="flex justify-center items-center py-10">
      <InnerPreLoaderComponent />
    </div>

    <div v-else>
      <PersonalInfo :data="personalData" :showBI="false" title="Dados" editRoute="inflow_edit" />
  </div>
  <modal :show="showErrorModal" @close="showErrorModal = false">
      <div class="text-left">
        <h2 class="text-xl font-bold mb-4 uppercase">{{ errorMessage.code }}</h2>
        <p class="mb-4">{{ errorMessage.message }}</p>
        <CustomButton :style="'orange'" label="Entendido" @click="showErrorModal = false" />
      </div>
    </modal>
  </div>

</template>
<script>
import PersonalInfo from '@/components/profile/CardInfo.vue';
import InnerPreLoaderComponent from "../../components/preLoader/InnerPreLoader.vue";
import CustomButton from "@/components/CustomButton.vue";
import modal from "@/components/modal/Modal.vue";
import axios from "axios";

export default {
  components: {
    PersonalInfo,
    InnerPreLoaderComponent,
    modal,
    CustomButton
  },
  data() {
    return {
     
      personalData: {
        Tipo: '',
        Valor: '',
        Descrição: '',
      },
      isLoading: false,
      errorMessage: {
        code: "",
        message: ""
      },
      showErrorModal: false,
      
    }
  },
  methods: {
    async inflowDetails() {
      this.isLoading = true;
      this.errorMessage.code = "";
      this.errorMessage.message = "";
      this.showErrorModal = false;

      try {
        const token = localStorage.getItem("token");

        if (!token) {
          this.errorMessage.code = "Erro de autenticação";
          this.errorMessage.message = "Token não encontrado. Por favor, faça login novamente.";
          this.showErrorModal = true;
          return;
        }

        const customHeaderParams = {
          Authorization: `Bearer ${token}`,
        };

        const inflowId = this.$route.params.id;

        const response = await axios.get(`inflows/${inflowId}`, {
          headers: customHeaderParams,
        });

        if (response.status === 200 && response.data.success === true) {
          this.details = response.data.data;

          this.personalData.Tipo = this.details.type;
          this.personalData.Valor = this.details.value;
          this.personalData.Descrição = this.details.description;
        }else {
          this.errorMessage.code = "Erro ao carregar detalhes";
          this.errorMessage.message = "Algo deu errado ao carregar os detalhes da entrada. Tente novamente mais tarde.";
          this.showErrorModal = true;
        }
      } catch (error) {
        console.log("Erro ao exibir detalhe", error);
        this.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    handleError(error) {
      switch (error.response ? error.response.status : null) {
        case 401:
          this.errorMessage.code = "Token Inválido ou Expirado";
          this.errorMessage.message = "Sua sessão expirou. Faça login novamente.";
          break;
        case 403:
          this.errorMessage.code = "Acesso negado";
          this.errorMessage.message = "Você não tem permissão para realizar esta ação.";
          break;
        case 404:
          this.errorMessage.code = "Recurso não encontrado";
          this.errorMessage.message = "O recurso solicitado não foi encontrado.";
          break;
        default:
          if (error.response) {
            this.errorMessage.code = "Erro do Servidor";
            this.errorMessage.message = "Ocorreu um erro no servidor. Tente novamente mais tarde.";
          } else if (error.request) {
            this.errorMessage.code = "Sem Resposta do Servidor";
            this.errorMessage.message = "Não foi possível obter resposta do servidor. Verifique sua conexão de rede.";
          } else {
            this.errorMessage.code = "Erro na Requisição";
            this.errorMessage.message = "Ocorreu um erro ao enviar a requisição. Tente novamente.";
          }
          break;
      }
      this.showErrorModal = true;
    }
  },
  mounted() {
    this.inflowDetails();
  }
}


</script>
<style></style>