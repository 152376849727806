<template>
  <div class="m-4">
    <TitleComponent title="Registrar veículo"
      subtitle="Preencha devidamente todos os campos, para registar o novo veículo." />
  </div>

  <div class="m-4 text-justify bg-white py-14 px-7 border-y">
    <form @submit.prevent="showModal = true">

      <div class="grid gap-6 mb-6 md:grid-cols-2">
        <div>
          <InputFieldComponent label="Marca do seu veículo" placeholder="Digite  marca do veículo" id="name" required />
        </div>
        <div>
          <InputFieldComponent label="Modelo do veículo" placeholder="Digite o modelo do veículo" id="name" required />
        </div>
        <div>
          <InputFieldComponent label="Matrícula do veículo" placeholder="Digite a matrícula do veículo" id="name"
            required />
        </div>
        <div>
          <InputFieldComponent label="Kilometraagem do veículo" placeholder="Digite a kilometragem do veículo" id="name"
            required />
        </div>
        <div>
          <InputFieldComponent label="Número de lugares disponíveis" placeholder="2" id="phone" required
            type="number" />
        </div>
        <div>
          <SelectFieldComponent label="Tipo de veículo" id="genre" :options="genderOptions" required />
        </div>
      </div>
      <div class="">

        <FileUploadComponent uploadMessage="Clique aqui para anexar imagens do veículo (JPG,PNG)"
          label="Imagens do veículo (Dentro e fora)" id="" />

      </div>

      <div class="flex justify-end">

        <CustomButton label="Registrar veículo" size="medium" :style="'orange'" class="ml-6">
        </CustomButton>
      </div>
    </form>

    <modal :show="showModal" @close="showModal = false">
      <div class="text-left">
        <h2 class="text-xl font-extrabold mb-4 uppercase">Veículo registrado com sucesso!!</h2>
        <p class="mb-4">O seu novo veículo foi registrado. Agora, essas novas informações poderão ser usadas em toda
          plataforma.</p>
        <CustomButton label="Entedido" :style="'orange'" size="medium" @click="showModal = false" />
      </div>
    </modal>

  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent.vue";
import InputFieldComponent from "@/components/form/InputFieldComponent.vue";
import SelectFieldComponent from "@/components/form/SelectFieldComponent.vue"
import FileUploadComponent from '@/components/form/FileUploadComponent.vue';
import modal from '@/components/modal/Modal';
import CustomButton from '@/components/CustomButton.vue';

export default {
  components: {
    TitleComponent,
    InputFieldComponent,
    SelectFieldComponent,
    FileUploadComponent,
    CustomButton,
    modal,
  },
  data() {
    return {
      genderOptions: [
        { label: 'Masculino', value: 'male' },
        { label: 'Feminino', value: 'female' }
      ],
      municipalityOptions: [
        { label: 'Desenvolvedor', value: 'developer' },
        { label: 'Designer', value: 'designer' },
        { label: 'Gerente de Projeto', value: 'project_manager' }
      ],
      provinceOptions: [
        { label: 'Desenvolvedor', value: 'developer' },
        { label: 'Designer', value: 'designer' },
        { label: 'Gerente de Projeto', value: 'project_manager' }
      ],
      showModal: false
    };
  },
}
</script>

<style></style>