<template>
  <div class="relative min-h-screen flex items-center justify-center">

    <overlayPreLoader :isLoading="isLoading" />

    <div class="max-w-md w-full">
      <logo :src="logoSrc" />
      <div class="bg-white shadow-md rounded-3xl p-12">
        <h3 id="montserrat" class="text-[40px] text-left mb-3">LOGIN</h3>
        <p class="text-xl mb-12">
          Use as suas credenciais para ter acesso a sua conta.
        </p>
        <form @submit.prevent="login()">
          <div class="mb-6">
            <input placeholder="Email favorito" type="email" v-model="email"
              class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5" />
          </div>
          <div class="mb-8">
            <input placeholder="Sua password" type="password" v-model="password"
              class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5" />
          </div>
          <div class="text-end mb-8">
            <a href="#" class="font-semibold">Esqueceu a password</a>
          </div>
          <div class="grid mb-12">
            <CustomButton label="Entrar" :style="'orange'" size="large" class="rounded-xl" />
          </div>
        </form>
        <div class="text-center mb-6">
          Não tem uma conta? <a href="#" class="font-semibold">Criar</a>
        </div>
      </div>
    </div>

    <img class="fixed -bottom-11 -right-8 -z-50 h-auto w-72 object-cover" src="../../assets/img/PNG/Icon/Asset 9.png"
      alt="Imagem" />

    <modal :show="showErrorModal" @close="showErrorModal = false">
      <div class="text-left">
        <h2 class="text-xl font-bold mb-4 uppercase">{{ errorMessage.code }}</h2>
        <p class="mb-4">{{ errorMessage.message }}</p>
        <CustomButton :style="'orange'" label="Entendido" @click="showErrorModal = false" />
      </div>
    </modal>

  </div>
</template>
<script>
// import InputFieldComponent from "@/components/form/InputFieldComponent.vue";
import CustomButton from "@/components/CustomButton.vue";
import Logo from "@/components/LogoComponent.vue";
import axios from "axios";
import modal from '@/components/modal/Modal';
import overlayPreLoader from '@/components/preLoader/OverlayPreLoader.vue';

export default {
  components: {
    // InputFieldComponent,
    CustomButton,
    Logo,
    modal,
    overlayPreLoader
  },
  data() {
    return {
      email: "",
      password: "",
      isLoading: false,
      logoSrc: require("../../assets/img/PNG/Logo sem fundo/Horizontal logo sem slogan.png"),
      errorMessage: {
        code: "",
        message: ""
      },
      showErrorModal: false
    };
  },

  methods: {
    async login() {
      this.errorMessage.code = "";
      this.errorMessage.message = "";
      this.showErrorModal = false;

      try {
        if (this.email && this.password) {
          this.isLoading = true;
          this.showErrorModal = false;

          const loginData = {
            email: this.email,
            password: this.password,
          };

          const response = await axios.post(
            `auth/signin-email-password`,
            loginData
          );

          if (response.status === 200 && response.data.success === true && response.data.data.access_token) {
            localStorage.setItem("token", response.data.data.access_token);
            localStorage.setItem("id", response.data.data.user.id);
            localStorage.setItem("user_id", response.data.data.user.profile.user_id);
            localStorage.setItem("isLoggedIn", true);
            await this.$router.push("/");
            // console.log("Login 200 OK");
          } else {
            this.errorMessage.code = "Credenciais inválidas";
            this.errorMessage.message = "Por favor, verifique seu email e senha.";
            this.showErrorModal = true;
          }
        } else {
          this.errorMessage.code = "Campos obrigatórios";
          this.errorMessage.message = "Por favor, preencha todos os campos.";
          this.showErrorModal = true;
          return;
        }
      } catch (error) {
        console.error("Erro:", error);

        this.isLoading = false;
        let validationErrors;


        switch (error.response ? error.response.status : null) {
          case 422:
            validationErrors = error.response.data.error.errors;
            if (validationErrors) {
              const firstErrorKey = Object.keys(validationErrors)[0];
              this.errorMessage.code = "Erro de Validação";
              this.errorMessage.message = validationErrors[firstErrorKey][0];
            } else {
              this.errorMessage.code = "Erro de Validação";
              this.errorMessage.message = "Erro de validação desconhecido.";
            }
            break;
          case 401:
            this.errorMessage.code = "Não Autorizado";
            this.errorMessage.message = "Credenciais inválidas. Por favor, verifique seu email e senha.";
            break;
          case 403:
            this.errorMessage.code = "Acesso Negado";
            this.errorMessage.message = "Você não tem permissão para realizar esta ação.";
            break;
          default:
          if (error.response) {
              this.errorMessage.code = "Erro do Servidor";
              this.errorMessage.message = "Ocorreu um erro no servidor. Tente novamente mais tarde.";
            } else if (error.request) {
              this.errorMessage.code = "Sem Resposta do Servidor";
              this.errorMessage.message = "Não foi possível obter resposta do servidor. Verifique sua conexão de rede.";
            } else {
              this.errorMessage.code = "Erro na Requisição";
              this.errorMessage.message = "Ocorreu um erro ao enviar a requisição. Tente novamente.";
            }
            break;
        }


        this.showErrorModal = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style>
@font-face {
  font-family: Montserrat;
  src: url("/src/assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
}

#montserrat {
  font-family: "Montserrat";
}
</style>