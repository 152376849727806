<template>
  <div class="m-4">
    <TitleComponent title="Atualizar dados de perfil"
      subtitle="Preencha devidamente todos os campos para editar os seus dados e, clique no botão abaixo para salvar." />
  </div>

  <div class="m-4 text-justify bg-white py-14 px-7 border-y">

    <form @submit.prevent="showModal = true">
      <div class="mb-6">
        <InputFieldComponent label="Nome do usuário" placeholder="Digite o nome do usuário" id="name" required />
      </div>
      <div class="grid gap-6 mb-6 md:grid-cols-2">

        <div>
          <InputFieldComponent label="Nº de Bilhete" placeholder="Digite o nº do bilhete" id="bi" required />
        </div>
        <div>
          <InputFieldComponent label="Número de telefone" placeholder="Ex.: +244 987654321" id="phone" required type="number" />
        </div>
      </div>
      <div class="mb-6">
        <InputFieldComponent label="Email" placeholder="Ex.: email@exemplo.com" required type="email" id="email" />
      </div>
      <div class="grid gap-6 mb-6 md:grid-cols-2">

        <div>
          <SelectFieldComponent label="Gênero" id="genre" :options="genderOptions" required />
        </div>
      </div>
      <div class="grid gap-6 mb-6 md:grid-cols-2">

        <div>
          <SelectFieldComponent label="Província" id="province" :options="provinceOptions" required />
        </div>
        <div>
          <SelectFieldComponent label="Município" id="municipality" :options="municipalityOptions" required />
        </div>
        <div class="">
          <InputFieldComponent label="Senha" placeholder="•••••••••" required type="password" id="password" />
        </div>
        <div class="">
          <InputFieldComponent label="Confirmar senha" placeholder="•••••••••" required type="password" id="confirmPassword"/>

        </div>
      </div>

      <div class="flex justify-end">
        <CustomButton :style="'orange'" label="Atualizar dados"/>
      </div>

    </form>

    <modal :show="showModal" @close="showModal = false">
      <div class="text-left">
        <h2 class="text-xl font-bold mb-4 uppercase">Atualizar Dados do perfil?</h2>
        <p class="mb-4">Clique no "sim", para confirmar este evento, e atualizar os dados.</p>
        
        <div class="grid grid-flow-col justify-stretch">
          <CustomButton :style="'gray'" label="Não" @click="showModal = false" textColor="'text-wewa-orange'"/>
          <CustomButton :style="'gray'" label="Sim" @click="showModal2 = true" class="ml-3" textColor="'text-wewa-orange'"/>
        </div>
        
      </div>
    </modal>
    <modal :show="showModal2" @close="showModal2 = false">
      <div class="text-left">
        <h2 class="text-xl font-bold mb-4 uppercase">Atualizar Dados do perfil?</h2>
        <p class="mb-4">A plataforma usará de agora em diante estes novos dados para o seu usuário.</p>
        
        <CustomButton :style="'orange'" label="Entedido" @click="showModal2 = false"/>
      </div>
    </modal>

  </div>
</template>

<script>
import TitleComponent from "../../components/TitleComponent.vue";
import InputFieldComponent from "@/components/form/InputFieldComponent.vue";
import SelectFieldComponent from "@/components/form/SelectFieldComponent.vue"
import CustomButton from "@/components/CustomButton.vue";
import modal from '@/components/modal/Modal';

export default {
  components: {
    TitleComponent,
    InputFieldComponent,
    SelectFieldComponent,
    CustomButton,
    modal,
  },
  data() {
    return {
      genderOptions: [
        { label: 'Masculino', value: 'male' },
        { label: 'Feminino', value: 'female' }
      ],
      municipalityOptions: [
        { label: 'Desenvolvedor', value: 'developer' },
        { label: 'Designer', value: 'designer' },
        { label: 'Gerente de Projeto', value: 'project_manager' }
      ],
      provinceOptions: [
        { label: 'Desenvolvedor', value: 'developer' },
        { label: 'Designer', value: 'designer' },
        { label: 'Gerente de Projeto', value: 'project_manager' }
      ],
      showModal: false,
      showModal2: false,
    };
  },
}
</script>

<style></style>