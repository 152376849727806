export default function (to, from, next) {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("id");
    const isAuthenticated = localStorage.getItem("isLoggedIn");
    if (isAuthenticated === "true" && token && id) {
      next("/");
    } else {
      next();
    }
  }
  