<template>
  <div class="m-4">
    <TitleComponent title="Passageiros" subtitle="Tenha o controle de todos os dados de passageiros do sistema." showButton="false" />

    <div v-if="isLoading" class="flex justify-center items-center py-10">
      <InnerPreLoaderComponent />
    </div>
    
    <div v-else>

    <TableComponent :columns="colunas" :rows="passengers" :filters="filters" :filterKey="filterKey" :idKey="idKey" :urlPrefix="urlPrefix" :showDelete="false" :showUpdate="false" />
    <div
      class="pagination flex flex-row items-center justify-center gap-4 py-5"
    >
      <button
        class="bg-white text-wewa-orange px-3 py-2 rounded-lg"
        @click="prevPage"
        :disabled="currentPage === 1"
      >
        Anterior
      </button>
      <span>{{ currentPage }} de {{ totalPages }}</span>
      <button
        class="bg-white text-wewa-orange px-3 py-2 rounded-lg"
        @click="nextPage"
        :disabled="currentPage === totalPages"
      >
        Próxima
      </button>
    </div>
    </div>
    <modal :show="showErrorModal" @close="showErrorModal = false">
      <div class="text-left">
        <h2 class="text-xl font-bold mb-4 uppercase">{{errorMessage.code}}</h2>
        <p class="mb-4">{{ errorMessage.message }}</p>
        <CustomButton :style="'orange'" label="Entendido" @click="showErrorModal = false" />
      </div>
    </modal>
  </div>
</template>

<script>
import TableComponent from "../../components/TableComponent.vue";
import TitleComponent from "../../components/TitleComponent.vue";
import InnerPreLoaderComponent from "../../components/preLoader/InnerPreLoader.vue"; 
import CustomButton from "@/components/CustomButton.vue";
import modal from "@/components/modal/Modal.vue";
import axios from "axios"
export default {
 components:{
  TableComponent,
  TitleComponent,
  InnerPreLoaderComponent,
    modal, 
    CustomButton
 },
 data(){
  return{
    colunas: ['ID', 'Nome', 'Nº do bilhete', 'Nº de telefone'],
    passengers: [],
      currentPage: 1,
      passengersPerPage: 5,
      totalPages: 0,
    filters: [],
      filterKey: 'id',
      idKey: 'id',
      urlPrefix: 'passenger' ,
      isLoading: false,
      errorMessage: {
        code: "",
        message: ""
      },
      showErrorModal: false
  };
 },
 methods: {
    async listPassangers() {
      this.isLoading = true;
      this.errorMessage.code = "";
      this.errorMessage.message = "";
      this.showErrorModal = false;
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          this.errorMessage.code = "Erro de autenticação";
          this.errorMessage.message = "Token não encontrado. Por favor, faça login novamente.";
          this.showErrorModal = true;
          await this.$router.push("/");
          return;
        }

        const customHeaderParams = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get(`passengers`, {
          headers: customHeaderParams,
        });


        if (response.status === 200 && response.data.success === true) {

          this.passengers = response.data.data.map((passenger) => {
    // Verifica se passenger.user existe, caso contrário, atribui valores padrão
    const firstName = passenger.user ? passenger.user.first_name : "";
    const lastName = passenger.user ? passenger.user.last_name : "";
    const email = passenger.user ? passenger.user.email : "";
    const phoneNumber = passenger.user ? passenger.user.phone_number : "";

    // Cria o objeto de passageiro com os dados processados
    return {
        id: passenger.id,
        full_name: (firstName + " " + lastName).trim().substring(0, 15) +
            (firstName.length + lastName.length > 15 ? "..." : ""),
        email: email.substring(0, 18) + (email.length > 18 ? "..." : ""),
        phone_number: phoneNumber
    };
});


          // Calcular o número total de páginas
          this.totalPages = Math.ceil(this.passengers.length / this.passengersPerPage);

          // Extrair os usuários para a página atual
          const startIndex = (this.currentPage - 1) * this.passengersPerPage;
          const endIndex = startIndex + this.passengersPerPage;
          this.passengers = this.passengers.slice(startIndex, endIndex);

        } else {
          this.errorMessage.code = "Erro ao obter usuários";
              this.errorMessage.message = "Ocorreu um erro ao obter usuários. Tente novamente mais tarde.";
              this.showErrorModal = true
        }
      } catch (error) {
        console.error("Erro:", error);
        switch (error.response ? error.response.status : null) {
          case 401:
          this.errorMessage.code = "Token Inválido ou Expirado";
          this.errorMessage.message = "Sua sessão expirou. Faça login novamente.";
            break;
          case 403:
            this.errorMessage.code = "Acesso negado";
            this.errorMessage.message = "Você não tem permissão para realizar esta ação.";
            break;
          case 404:
            this.errorMessage.code = "Recurso não encontrado";
            this.errorMessage.message = "O recurso solicitado não foi encontrado.";
            break;
          default:
          if (error.response) {
              this.errorMessage.code = "Erro do Servidor";
              this.errorMessage.message = "Ocorreu um erro no servidor. Tente novamente mais tarde.";
            } else if (error.request) {
              this.errorMessage.code = "Sem Resposta do Servidor";
              this.errorMessage.message = "Não foi possível obter resposta do servidor. Verifique sua conexão de rede.";
            } else {
              this.errorMessage.code = "Erro na Requisição";
              this.errorMessage.message = "Ocorreu um erro ao enviar a requisição. Tente novamente.";
            }
            break;
        }
          this.showErrorModal = true;
      }finally {
        this.isLoading = false;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.listPassangers();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.listPassangers();
      }
    },
  },
  mounted() {
    this.listPassangers();
  },
}
</script>

<style></style>