<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {

  }
}
</script>

<style>
@font-face {
  font-family: Poppins;
  src: url('/src/assets/fonts/Poppins/Poppins-Regular.ttf')format('truetype');
}
body {
  font-family: 'Poppins',Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#fbfbfb ;
  color: black;
}

</style>
