<template>
  <div class="m-4">
    <TitleComponent
      title="Atualizar dados de perfil"
      subtitle="Preencha devidamente todos os campos para editar os seus dados e, clique no botão abaixo para salvar."
    />
  </div>

  <overlayPreLoader :isLoading="isLoadingSubmit" />

  <div class="m-4 text-justify bg-white py-14 px-7 border-y">
    <div v-if="isLoadingDetail" class="flex justify-center items-center py-10">
      <InnerPreLoaderComponent />
    </div>

    <div v-else>
    <form @submit.prevent="updateUser">
      <div class="grid gap-6 mb-6 md:grid-cols-2">
        <div>
          <label for="first_name" class="block mb-2 text-sm font-bold text-gray-900">Primeiro nome</label>
          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            placeholder="Digite o primeiro nome"
            type="text"
            id="first_name"
            v-model="first_name"
          />
        </div>
        <div>
          <label for="last_name" class="block mb-2 text-sm font-bold text-gray-900">Último nome</label>
          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            placeholder="Digite o segundo nome"
            type="text"
            id="last_name"
            v-model="last_name"
          />
        </div>
      </div>
      <div class="grid gap-6 mb-6 md:grid-cols-2">
        <div>
          <label for="phone_number" class="block mb-2 text-sm font-bold text-gray-900">Número de telefone</label>
          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            placeholder="Ex.: +244 987654321"
            type="text"
            id="phone_number"
            v-model="phone_number"
          />
        </div>
        <div>
          <label for="email" class="block mb-2 text-sm font-bold text-gray-900">Email</label>
          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            id="email"
            placeholder="Ex.: email@exemplo.com"
            type="email"
            v-model="email"
          />
        </div>
      </div>
      <div class="flex justify-end">
        <CustomButton
          label="Atualizar dados"
          :style="'orange'"
          size="large"
          type="submit"
        />
      </div>
    </form>
    </div>

    <modal :show="showModal" @close="showModal = false">
      <div class="text-left">
        <h2 class="text-xl font-bold mb-4 uppercase">
          Atualizar Dados do perfil?
        </h2>
        <p class="mb-4">
          Clique no "sim", para confirmar este evento, e atualizar os dados.
        </p>

        <div class="grid grid-flow-col justify-stretch">
          <CustomButton
            :style="'gray'"
            label="Não"
            @click="showModal = false"
            textColor="'text-wewa-orange'"
          />
          <CustomButton
            :style="'gray'"
            label="Sim"
            @click="showModal2 = true"
            class="ml-3"
            textColor="'text-wewa-orange'"
          />
        </div>
      </div>
    </modal>
    <modal :show="showModal2" @close="showModal2 = false">
      <div class="text-left">
        <h2 class="text-xl font-bold mb-4 uppercase">
          Atualizar Dados do perfil?
        </h2>
        <p class="mb-4">
          Clique no "sim", para confirmar este evento, e atualizar os dados.
        </p>

        <CustomButton
          :style="'orange'"
          label="Entedido"
          @click="showModal2 = false"
        />
      </div>
    </modal>
    <modal :show="showErrorModal" @close="showErrorModal = false">
      <div class="text-left">
        <h2 class="text-xl font-bold mb-4 uppercase">{{ errorMessage.code }}</h2>
        <p class="mb-4">{{ errorMessage.message }}</p>
        <CustomButton :style="'orange'" label="Entendido" @click="showErrorModal = false" />
      </div>
    </modal>
  </div>
</template>

<script>
import TitleComponent from "../../components/TitleComponent.vue";
import CustomButton from "@/components/CustomButton.vue";
import modal from "@/components/modal/Modal";
import overlayPreLoader from '@/components/preLoader/OverlayPreLoader.vue';
import InnerPreLoaderComponent from "../../components/preLoader/InnerPreLoader.vue"; 

import axios from "axios";

export default {
  components: {
    TitleComponent,
    // InputFieldComponent,
    // SelectFieldComponent,
    CustomButton,
    modal,
    overlayPreLoader,
    InnerPreLoaderComponent
  },
  data() {
    return {
      showModal: false,
      showModal2: false,
      email: "",
      phone_number: "",
      password: "",
      password_confirmation: "",
      role: "",
      first_name: "",
      last_name: "",
      isLoadingDetail: false,
      isLoadingSubmit: false,
      errorMessage: {
        code: "",
        message: ""
      },
      showErrorModal: false
    };
  },
  methods: {
    async detailUser() {
      this.isLoadingDetail=true;
      try {
        const userId = this.$route.params.id;
        const token = localStorage.getItem("token");

        if (!token) {
          this.errorMessage.code = "Erro de autenticação";
          this.errorMessage.message = "Token não encontrado. Por favor, faça login novamente.";
          this.showErrorModal = true;
          return;
        }

        const customHeaderParams = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get(`users/${userId}`, {
          headers: customHeaderParams,
        });


        if (response.status === 200 && response.data.success === true) {
          const userData = response.data.data;
          this.email = userData.email;
          this.phone_number = userData.phone_number;
          this.first_name = userData.first_name;
          this.last_name = userData.last_name;
        } else {
          this.errorMessage.code = "Erro ao obter usuários";
              this.errorMessage.message = "Ocorreu um erro. Por favor, tente novamente mais tarde.";
              this.showErrorModal = true
        }
      } catch (error) {
        console.error("Erro ao obter os dados do usuário:", error);
        this.errorMessage.code = "Erro ao obter os dados do usuário";
        this.errorMessage.message = "Ocorreu um erro ao tentar obter os dados do usuário. Por favor, tente novamente mais tarde.";
        this.showErrorModal = true;
      }finally{
        this.isLoadingDetail=false;
      }
    },
    async updateUser() {
      this.isLoadingSubmit=true;
      try {
        const updatedFields = {};

        if (this.email) {
          updatedFields.email = this.email;
        }

        if (this.phone_number) {
          updatedFields.phone_number = this.phone_number;
        }

        if (this.first_name) {
          updatedFields.first_name = this.first_name;
        }

        if (this.last_name) {
          updatedFields.last_name = this.last_name;
        }

        if (Object.keys(updatedFields).length === 0) {
          this.errorMessage.code = "Nenhum campo para atualizar.";
            this.errorMessage.message = "Nenhum campo recebeu atualização.";
            this.showErrorModal = true;
          return;
        }

        const token = localStorage.getItem("token");

        if (!token) {
          this.errorMessage.code = "Token Não Encontrado";
            this.errorMessage.message = "Token não encontrado no armazenamento local.";
            this.showErrorModal = true;
          return;
        }

        const customHeaderParams = {
          Authorization: `Bearer ${token}`,
        };

        const userId = this.$route.params.id;

        const response = await axios.put(`users/${userId}`, updatedFields, {
          headers: customHeaderParams,
        });

        // console.log("Editando", response);

        if (response.status === 200 && response.data.success === true) {
          this.showModal = true;
        }
      } catch (error) {
        this.isLoadingSubmit = false;

        let validationErrors;

        switch (error.response ? error.response.status : null) {
          case 422:
            validationErrors = error.response.data.error.errors;
            if (validationErrors) {
              const firstErrorKey = Object.keys(validationErrors)[0];
              this.errorMessage.code = "Erro de Validação";
              this.errorMessage.message = validationErrors[firstErrorKey][0];
            } else {
              this.errorMessage.code = "Erro de Validação";
              this.errorMessage.message = "Erro de validação desconhecido.";
            }
            break;
          case 401:
            this.errorMessage.code = "Token Inválido ou Expirado";
            this.errorMessage.message = "Sua sessão expirou. Faça login novamente.";
            break;
          case 403:
            this.errorMessage.code = "Acesso Negado";
            this.errorMessage.message = "Você não tem permissão para realizar esta ação.";
            break;
          case 404:
            this.errorMessage.code = "Recurso não encontrado";
            this.errorMessage.message = "O recurso solicitado não foi encontrado.";
            break;
          default:
            if (error.response) {
              this.errorMessage.code = "Erro do Servidor";
              this.errorMessage.message = "Ocorreu um erro no servidor. Tente novamente mais tarde.";
            } else if (error.request) {
              this.errorMessage.code = "Sem Resposta do Servidor";
              this.errorMessage.message = "Não foi possível obter resposta do servidor. Verifique sua conexão de rede.";
            } else {
              this.errorMessage.code = "Erro na Requisição";
              this.errorMessage.message = "Ocorreu um erro ao enviar a requisição. Tente novamente.";
            }
            break;
        }

        this.showErrorModal = true;
      }finally{
        this.isLoadingSubmit=false
      }
    },
  },
  async mounted() {
    await this.detailUser();
  },
};
</script>

<style></style>
