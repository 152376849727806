<template>
  <div class="text-start mt-16">
    <p class="font-bold text-6xl uppercase">{{ name }}</p>
    <p class="font-medium capitalize text-2xl mt-6">cargo: {{ role }}</p>
  </div>
</template>

<script>
export default {
  name: 'ProfileInfo',
  props: {
    name: String,
    role: String
  }
};
</script>

<style></style>
