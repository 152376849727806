<template>
  <div class="mb-6">
    <label :for="id" class="block mb-2 text-sm font-bold text-gray-900">{{ label }}</label>
    <input :type="type"
           :id="id"
           :name="name"
           :placeholder="placeholder"
           class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
           :required="required">
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    id: {
      type: String,
      required: false
    },
    name: String,
    type: {
      type: String,
      default: "text"
    },
    placeholder: String,
    required: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
</style>
