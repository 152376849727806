<template>
  <div class="m-4">
    <TitleComponent title="Feedbacks" subtitle="Tenha o controle de todos os feedbacks que os passageiros dão aos motoristas." showButton="false" />

    <TableComponent :columns="colunas" :rows="dados" :filters="filters" :filterKey="filterKey" :idKey="idKey" :urlPrefix="urlPrefix" />

  </div>
</template>

<script>
import TableComponent from "../../components/TableComponent.vue";
import TitleComponent from "../../components/TitleComponent.vue";

export default {
 components:{
  TableComponent,
  TitleComponent
 },
 data(){
  return{
    colunas: ['ID', 'Motorista', 'Horário', 'Classificação'],
    dados: [
      { id: 1, Nome: 'Apple MacBook Pro 17"', 'Nº do bilhete': 'Sliver', 'Nº de telefone': 'Laptop',ativo: true},
      { id: 2, Nome: 'Microsoft Surface Pro', 'Nº do bilhete': 'White', 'Nº de telefone': 'Laptop PC',ativo: true},
      { id: 3, Nome: 'Magic Mouse 2', 'Nº do bilhete': 'Black', 'Nº de telefone': 'Accessories',ativo: true}
    ],
    filters: [
      {texto:'Todos', valor:'ativo'},
    ],
      filterKey: 'id',
      idKey: 'id',
      urlPrefix: 'passenger' 
  };
 } 
}
</script>

<style></style>